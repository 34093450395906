<template>

    <div v-if="view === 'mobile'" class="h-24 bg-box rounded-lg mb-4 flex flex-row overflow-hidden shadow-md" @click="selectEvent(data)">

        <div class="h-full w-1/3 p-2">
            <div class="h-full w-full relative rounded-lg shadow-md bg-center"
                :style="'background-image: url(' + imageRoute + data.image + '); ' +
                'background-repeat: no-repeat; background-size: cover; background-position: center'">
                <!-- <div class="show-more-event-mobile cursor-pointer"></div> -->
            </div>
        </div>

        <div class="h-full w-2/3 flex flex-col">
                <div v-show="data.file" class="h-6 flex flex-row justify-end pt-1 items-center px-2">
                    <i class="mdi text-font-gray cursor-pointer" :class="extension(data.file) + ' ' + style(data.file)"></i>
                </div>
                <div class="h-auto py-2 flex flex-row justify-start items-center px-2">
                    <span class="font-semibold text-xxs" :class="{'text-purple': data.organizer_group != 'ey', 'text-aux': data.organizer_group == 'ey'}">
                        {{ data.date | moment("dddd, MMMM Do YYYY, h:mm a") }}</span>
                </div>
                <div class="h-auto pb-1 flex flex-row justify-start items-center px-2">
                    <span class="text-white font-medium text-xs truncate-2">{{ data.title }}</span>
                </div>
                <div class="flex-1 pt-1 px-2">
                    <p class="text-xxs c" v-html="data.body"></p>
                </div>
        </div>

    </div>
    
    <div v-else class="bg-box rounded-lg mb-4 flex overflow-hidden shadow-md">
        <div v-if="data.image && data.image != ''" class="h-full p-2">
            <div class="relative w-32 h-40 rounded-lg"
                :style="'background-image: url(' + imageRoute + data.image + '); ' +
                'background-repeat: no-repeat; background-size: cover; background-position: center'">
            </div>
        </div>

        <div class="px-4 py-6 flex-1 min-w-0 flex flex-col">
            <div class="flex-1 min-h-0">
                <p class="text-sm font-semibold truncate" :class="{'text-purple': data.organizer_group != 'ey', 'text-aux': data.organizer_group == 'ey'}">
                    {{ data.date | moment("dddd, MMMM Do YYYY, h:mm a") }}
                </p>

                <div class="cursor-pointer" @click="$emit('select', data)">
                    <p class="mt-5 text-white font-medium text-xl truncate-2"> {{ data.title }}</p>
                </div>

                <p v-if="data.body" class="mt-5 text-font-gray truncate-2"> {{ data.body }}</p>
            </div>

            <div class="flex mt-2 justify-end px-2 text-font-gray cursor-pointer">
                <div 
                    v-if="data.file"
                    @click="openFile(data.file, 'file')"
                >
                    <i :class="'mdi mdi-' + extension(data.file)"></i>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    props: ['data', 'view'],
    methods: {
        selectEvent(data) {
            this.$emit('selectEventChild', data)
        },
        openFile(file, type) { this.$openFile(file, type) },
        extension(data) {
            let result = ''
            if (data) result += this.$iconByExtension(data)
            return result
        },
        style(data) {
            let result = ''
            if (data !== '') result += this.$colorByExtension(data)
            return result
        },
    },
    computed: {
        imageRoute() { return this.$imageRoute }
    }
}
</script>